var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "mb-0", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-body",
        [
          _c(
            "b-tabs",
            {
              staticClass: "tabs-categories",
              attrs: {
                vertical: "",
                pills: "",
                "nav-wrapper-class": "nav-vertical",
              },
            },
            _vm._l(_vm.categories, function (category, index) {
              return _c(
                "b-tab",
                {
                  key: category.code,
                  attrs: {
                    active: category === category.code ? true : false,
                    title: category.title,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onTabChange(category, index)
                    },
                  },
                },
                [
                  _c(
                    "b-card-text",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _vm._v(" " + _vm._s(category.title) + " "),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("h3", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(category.subtitle)),
                      ]),
                      _vm.dataItems.length
                        ? _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              " Mova os itens abaixo para organizar a exibição. Os itens serão exibidos por ordem crescente. "
                            ),
                          ])
                        : _vm._e(),
                      _vm.loadingItems
                        ? _c(
                            "p",
                            { staticClass: "text-left py-3 mb-0" },
                            [
                              _c("b-spinner", {
                                staticClass: "mr-1",
                                attrs: { label: "Loading...", small: "" },
                              }),
                              _vm._v(" Verificando banners cadastrados... "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "draggable",
                        {
                          staticClass:
                            "list-group list-group-flush cursor-move my-2",
                          attrs: { tag: "ul" },
                          on: { change: _vm.onChangeSort },
                          model: {
                            value: _vm.dataItems,
                            callback: function ($$v) {
                              _vm.dataItems = $$v
                            },
                            expression: "dataItems",
                          },
                        },
                        _vm._l(_vm.dataItems, function (item, index) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: index,
                              staticClass: "pl-0 pr-0",
                              attrs: { tag: "li" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "sort-number mr-0" },
                                    [
                                      _c("i", {
                                        staticClass: "bi bi-arrows-move",
                                      }),
                                    ]
                                  ),
                                  _c("div", { staticClass: "sort-number" }, [
                                    _vm._v(_vm._s(index + 1) + "º"),
                                  ]),
                                  _c("b-avatar", {
                                    staticClass: "mr-1",
                                    attrs: {
                                      rounded: "sm",
                                      size: "42",
                                      src: item.image,
                                      title: item.title,
                                      target: "_blank",
                                    },
                                  }),
                                  _c("div", { staticClass: "d-flex-left" }, [
                                    _c(
                                      "p",
                                      { staticClass: "font-weight-bold mb-0" },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    ),
                                  ]),
                                  _c("div", {
                                    staticClass: "ml-auto",
                                    staticStyle: { "min-width": "100px" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                      _vm.updating
                        ? _c(
                            "p",
                            { staticClass: "mb-0te text-success" },
                            [
                              _c("b-spinner", {
                                staticClass: "mr-1",
                                attrs: { label: "Loading...", small: "" },
                              }),
                              _vm._v(" Atualizando... "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }