<template>
  <b-card no-body class="mb-0">
    <b-card-body>
      <b-tabs
        vertical
        pills
        nav-wrapper-class="nav-vertical"
        class="tabs-categories"
      >
        <b-tab
          v-for="(category, index) in categories"
          :active="category === category.code ? true : false"
          :title="category.title"
          :key="category.code"
          @click="onTabChange(category, index)"
        >
          <b-card-text>
            <template #title>
              {{ category.title }}
            </template>

            <h3 class="mb-2">{{ category.subtitle }}</h3>

            <p v-if="dataItems.length" class="mb-0">
              Mova os itens abaixo para organizar a exibição. Os itens serão exibidos por ordem
              crescente.
            </p>

            <p class="text-left py-3 mb-0" v-if="loadingItems">
              <b-spinner label="Loading..." small class="mr-1" /> Verificando
              banners cadastrados...
            </p>

            <draggable
              v-model="dataItems"
              class="list-group list-group-flush cursor-move my-2"
              tag="ul"
              @change="onChangeSort"
            >
              <b-list-group-item
                v-for="(item, index) in dataItems"
                :key="index"
                tag="li"
                class="pl-0 pr-0"
              >
                <div class="d-flex justify-content-center">
                  <div class="sort-number mr-0">
                    <i class="bi bi-arrows-move"></i>
                  </div>
                  <div class="sort-number">{{ index + 1 }}º</div>
                  <b-avatar
                    rounded="sm"
                    size="42"
                    :src="item.image"
                    :title="item.title"
                    target="_blank"
                    class="mr-1"
                  />
                  
                  <div class="d-flex-left">
                    <p class="font-weight-bold mb-0">
                      {{ item.title }}
                    </p>
                  </div>

                  <div class="ml-auto" style="min-width: 100px">
                    
                  </div>
                </div>
              </b-list-group-item>
            </draggable>

            <p v-if="updating" class="mb-0te text-success">
              <b-spinner label="Loading..." small class="mr-1" />
              Atualizando...
            </p>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BListGroupItem,
  BCardBody,
  BTabs,
  BTab,
  BCardText,
  BSpinner,
  BListGroup,
  BBadge,
  BAvatar,
} from "bootstrap-vue";
import draggable from "vuedraggable";

export default {
  components: {
    BTabs,
    BListGroupItem,
    BListGroup,
    BTab,
    BBadge,
    BAvatar,
    BCard,
    BCardBody,
    BCardText,
    draggable,
    BSpinner,
  },
  data() {
    return {
      category: {
        title: "Início",
        code: "home",
        name: 'home',
        subtitle: 'Página inicial'
      },
      dataItems: [],
      loadingItems: false,
      updating: false,
      categories: [
        {
          title: "Home - Concursos",
          code: "home",
          name: 'home',
          subtitle: 'Home - Concursos'
        },
        {
          title: "Home - Notícias",
          code: 'news',
          name: 'news',
          subtitle: 'Home - Notícias'
        },
        {
          title: "Concursos",
          code: "contests",
          name: 'contests',
          subtitle: 'Página de concursos'
        },
        {
          title: "Online",
          code: 2,
          name: 'products',
          subtitle: 'Página de cursos online'
        },
        {
          title: "Presencial",
          code: 1,
          name: 'products',
          subtitle: 'Página de cursos presenciais'
        },
        {
          title: "Live",
          code: 3,
          name: 'products',
          subtitle: 'Página de cursos lives'
        },
        {
          title: "Rápido",
          code: 4,
          name: 'products',
          subtitle: 'Página de cursos rápidos'
        },
        {
          title: "Grátis",
          code: 5,
          name: 'products',
          subtitle: 'Página de cursos grátis'
        },
        {
          title: "Apostila",
          code: 6,
          name: 'products',
          subtitle: 'Página de apostilas'
        },
      ],
    };
  },
  created() {
    this.getData()
  },
  methods: {
    onChangeSort(event) {
      if (event.moved) {
        this.updating = true;

        let itemsArray = this.dataItems;

        for (let item of this.dataItems) {
          delete item.image
          delete item.label_image
          delete item.sort
          delete item.title
        }

        let route = '';

        switch(this.category.name) {
          case 'contests':
            route = 'updateContests'
            break;
          case 'products':
            route = 'updateProducts'
            break;
          case 'home':
            route = 'updateContestsHome'
            break;
          case 'news':
            route = 'updateNewsHome'
            break;
        }

        this.$store
          .dispatch(`Sort/${route}`, { id: this.category.code, items: itemsArray })
          .then(() => {
            this.notifyDefault("success");
            this.getData();
          })
          .catch(() => {
            this.notifyDefault("error", 'Oops, algo não saiu como esperado.');
          })
          .finally(() => {
            this.updating = false;
          });
      }
    },
    onTabChange(category) {
      this.category = category;
      
      this.getData();
    },
    async getData() {
      this.loadingItems = true;
      this.dataItems = [];

      let route = '';

      switch(this.category.name) {
        case 'contests':
          route = 'allContests'
          break;
        case 'products':
          route = 'allProducts'
          break;
        case 'home':
          route = 'allContestsHome'
          break;
        case 'news':
          route = 'allNewsHome'
          break;
      }

      this.$store
        .dispatch(`Sort/${route}`, { id: this.category.code })
        .then((data) => {
          if (data) {
            this.dataItems = data;
          }
        })
        .finally(() => {
          this.loadingItems = false;
        });
    },
  },
};
</script>
